const methods = {
    // 연령 구분 색상
    age_group_color(age_group){
        switch(age_group){
            case "마스터":
                return "color:#FF7A51"
            case "시니어":
                return "color:#0B9E00"
            case "주니어":
                return "color:#FFE165"
            case "전체연령":
                return "color:#615DFA"
            default:
                return "color:black"
        }
    },

    // 국가 목록 불러오기
    async load_country_list() {
        try {
            const res = await this.$http.post("/api/official_league/select/country")
            return res.data
        } catch (error) {
            console.error(error)
            return null
        }
    },

    // 국가 이름 찾기
    country_name(code, country_list) {
        if(country_list.length == 0) return

        const country = country_list.find(item => item.code === code)
        return country ? country.country_kr : null
    },
}

export default methods