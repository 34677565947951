import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        is_logined: false,
        user_id: "",
        nickname: "",
        email: "",
        profile_image: "",
        point: "",
        type: "",
        created: "",
        collection_show: false,
        email_alarm_agree: false,

        // 키워드 (공식대회)
        keyword_list: [
            {
                type: "date",
                value: "3개월",
            }
        ],

        // 키워드 (공식대회 > 덱 순위)
        deck_keyword_list: [
            {
                type: "date",
                value: "최근 3개월",
            }
        ],

        // 키워드 (공식대회 > 플레이어)
        player_keyword_list: [
            {
                type: "date",
                value: "최근 3개월",
            }
        ],

        // 광고 배너
        ads_banner: {
            community: false,
            day_check: false,
            official_league: false,
            card_search_detail: false,
            collection: false,
            card_ranking: false,
            card_trade: false,
            deck_recipe: false,
            mypage: false
        }
    },

    getters: {

    },

    mutations: {
        // 로그인
        login: (state, payload) => {
            state.is_logined = true
            state.user_id = payload.user_id
            state.nickname = payload.nickname
            state.email = payload.email
            state.profile_image = payload.profile_image
            state.point = payload.point
            state.type = payload.type
            state.created = payload.created
            state.collection_show = payload.collection_show
            state.email_alarm_agree = payload.email_alarm_agree
        },

        // 로그아웃
        logout: (state) => {
            state.is_logined = false
            state.user_id = ""
            state.nickname = ""
            state.email = ""
            state.profile_image = ""
            state.point = ""
            state.type = ""
            state.created = ""
            state.collection_show = false
            state.email_alarm_agree = false
        },

        // 닉네임 수정
        nicknameUpdate: (state, payload) => {
            state.nickname = payload.nickname
        },

        // 프로필 이미지 수정
        update_profile_image: (state, payload) => {
            state.profile_image = payload
        },

        // 콜렉션북 공개여부 수정
        update_collection_show: (state, payload) => {
            console.log(payload)
            state.collection_show = payload
        },

        // 이메일 알림 수신여부 수정
        update_email_alarm_agree: (state, payload) => {
            console.log(payload)
            state.email_alarm_agree = payload
        },

        // 키워드 (공식대회)
        setKeywordList(state, list) {
            state.keyword_list = list
        },

        // 키워드 (공식대회 > 덱 순위)
        setDeckKeywordList(state, list) {
            state.deck_keyword_list = list
        },

        // 키워드 (공식대회 > 플레이어)
        setPlayerKeywordList(state, list) {
            state.player_keyword_list = list
        }
    },

    actions: {

    },

    modules: {

    },

    plugins: [
        createPersistedState({
            paths: [
                'is_logined',
                'user_id',
                'nickname',
                'email',
                'profile_image',
                'point',
                'type',
                'created',
                'collection_show',
                'email_alarm_agree',
                'ads_banner'
            ]
        })
    ]
})
