const methods = {
    // 확인하고 알림 전송하기
    checkAndSend(type, nickname, user_id) {
        // 콘솔 (비활성화 대체)
        console.log("$dbAlarm", type, nickname, user_id)

        // this.$http.post("/api/admin/main/alarm/checkAndSend", {
        //     params: {
        //         type: type,
        //         nickname: nickname,
        //         user_id: user_id
        //     }
        // })
    },
}

export default methods