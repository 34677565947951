const methods = {
    // 마지막 접속일 업데이트
    lastVisit(user_id) {
        this.$http.post("/api/auth/update/lastVisit", {
            params: {
                user_id: user_id
            }
        })
    },
}

export default methods