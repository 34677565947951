import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import { routes } from './routes.js'
import store from './store.js'
import vuetify from './plugins/vuetify.js'
import axios from 'axios'
import lodash from 'lodash'
import '@babel/polyfill'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import VueMoment from 'vue-moment'
import VueClipboard from 'vue-clipboard2'
import VueSnip from 'vue-snip'
import Meta from 'vue-meta'
import VueExcelXlsx from "vue-excel-xlsx"

import '@mdi/font/css/materialdesignicons.css'

// Cookie
import Cookies from 'js-cookie'
Vue.prototype.$Cookies = Cookies

// Vue2는 Composition API 없으면 이 방식으로 this.dayjs 형식으로 사용 가능
import dayjsGlobal from './plugins/dayjs.js'
Vue.use(dayjsGlobal)

import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd.min'
import 'vuejs-clipper/dist/vuejs-clipper.css'
Vue.use(VueRx)
Vue.use(VuejsClipper)

// CustomSnackBar
import globalSnackbar from '@/plugins/globalSnackbar'
Vue.use(globalSnackbar, { vuetify })

import CustomMethods from './methods.js'
Vue.use(CustomMethods)
Vue.use(VueExcelXlsx)

Vue.use(VueMoment)
Vue.use(Router)
Vue.use(
    Chartkick.use(Chart)
)

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(VueClipboard)
Vue.use(VueSnip)
Vue.use(Meta)

Vue.config.productionTip = false
Vue.config.devtools = true

export const eventBus = new Vue()

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // 항상 위로
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
})

router.beforeEach(async(to, from, next) => {
    // 복사 해킹 사이트 차단
    const host = window.location.hostname
    if (
        host !== 'icu.gg'
        && host !== 'www.icu.gg'
        && host !== '93.188.161.17'
        && host !== 'localhost'
    ) {
        window.location.href = 'https://icu.gg/caution'
    } else {
        next() // 정상적인 라우팅 처리
    }


    // 접속로그 입력하기
    axios.post("/api/visited_log/insert", {
        params: {
            path: to.fullPath
        }
    })

    let title = ''

    // 마이페이지
    if(to.path.startsWith('/mypage'))
    {
        title = '마이페이지'
    }
    
    // 관리자페이지
    else if(to.path.startsWith('/admin'))
    {
        title = '관리자페이지'
    }
    
    // 카드목록
    else if(to.path.startsWith('/card/list'))
    {
        title = '카드목록'
    }
    
    // 카드상세
    else if(to.path.startsWith('/card/detail'))
    {
        // 카드 조회수 증가
        await axios.post('/api/card/update/view_count/increase', {
            params: {
                card_id: to.query.id
            }
        })

        // 카드 이름
        await axios.post('/api/card/detail/trade/group/import/card/kr', {
            params: {
                card_id: to.query.id
            }
        }).then((res => {
            if(res.data.length){
                title = res.data[0].name
            }
        }))
    }
    
    // 카드거래
    else if(to.path.startsWith('/trade'))
    {
        title = '카드거래'

        if(to.path.startsWith('/trade/post/read'))
        {
            // 게시글 제목
            await axios.post('/api/trade/post/select', {
                params: {
                    id: to.query.id
                }
            }).then(res => {
                if(res.data.length){
                    title = res.data[0].title
                }
            })
        }
    }
    
    // 콜렉션북
    else if(to.path.startsWith('/collection'))
    {
        title = '콜렉션북'
    }
    
    // 덱레시피
    else if(to.path.startsWith('/deck'))
    {
        title = '덱레시피'

        if(to.path.startsWith('/deck/read'))
        {
            // 게시글 제목
            await axios.post('/api/new/deck/select/specific', {
                params: {
                    id: to.query.id
                }
            }).then(res => {
                if(res.data.length){
                    title = res.data[0].title
                }
            })
        }
    }
    
    // 포인트몰
    else if(to.path.startsWith('/pointmall'))
    {
        title = '포인트몰'

        if(to.path.startsWith('/pointmall/read'))
        {
            // 게시글 제목
            await axios.post('/api/pointmall/select/read', {
                params: {
                    id: to.query.id
                }
            }).then(res => {
                if(res.data.length){
                    title = res.data[0].title
                }
            })
        }
    }
    
    // 카드랭킹
    else if(to.path.startsWith('/rank'))
    {
        title = '카드랭킹'
    }
    
    // 게시판
    else if(to.path.startsWith('/board'))
    {
        // 게시판 이름
        title = to.query.board_name

        // read 시 조회수 증가
        if(to.path.startsWith('/board/read'))
        {
            // console.log(from.name)
            // if(from.name != null){
                await axios.post('/api/board/read/view_count/increase', {
                    params: {
                        id: to.query.id
                    }
                })
            // }

            // 게시글 제목
            await axios.post('/api/board/read', {
                params: {
                    id: to.query.id
                }
            }).then(res => {
                if(res.data.length){
                    title = res.data[0].title + " > " + to.query.board_name
                }
            })
        }
    }

    document.title = title + (title==''?'':' - ') + '포켓몬카드 DB/커뮤니티 ICU(너정다)'

    // title 임시변경
    // document.title = '테스트 페이지' + title
    next()
})

new Vue({
    router,
    store,
    vuetify,
    lodash,
    Meta,
    render: h => h(App)
}).$mount('#app')