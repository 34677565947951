<template>
    <div id="app">
        <router-view
            v-if="!is_baned"
        ></router-view>

        <div
            v-if="is_baned"
            style="padding:12px 18px; font-size:24px;"
        >
            이 아이피는 운영자에 의해 차단되었습니다.
        </div>
    </div>
</template>
<script>
export default {
    name: 'App',

    data: () => ({
        is_baned: false
    }),

    mounted(){
        // 광고 배너 표시 여부 확인
        this.$http.post('/api/check/ads_banner')
        .then((res) => {
            if(res.data.length){
                res.data.forEach((banner) => {
                    if (this.$store.state.ads_banner.hasOwnProperty(banner.type)) {
                        this.$store.state.ads_banner[banner.type] = banner.is_show === 1;
                    }
                })
            }
        })

        // 마지막 접속일 기록
        if(this.$store.state.is_logined){
            this.$lastVisit(this.$store.state.user_id)

            // Pokemon 도원결의 삼형제 강제 로그아웃
            if(
                this.$store.state.user_id == 'Pokemon'
                || this.$store.state.user_id == 'pokémon'
                || this.$store.state.user_id == 'Pokémon'
            ){
                this.$router.push('/auth/logout')
            }
        }

        // 밴 아이피 확인
        this.$http.post('/api/check/ip')
        .then((res) => {
            this.is_baned = res.data
        })

        // 'visited' 쿠키 확인
        if (!this.$Cookies.get('visited')) {
            this.$http.post("/api/visited_count/insert")
            .then((res) => {
                // console.log(res)
            })
            
            this.$Cookies.set('visited', 'true', { expires: 1 })
        }
    },
}
</script>
<style>
p, span, font {
    color:#424361;
}

.flexible_table .v-data-table__wrapper > table {
    table-layout:fixed;
}
</style>
<style>
/* 입력란 기본 테두리 색상 */
.v-text-field >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>