import dayjs from 'dayjs'

export default {
install(Vue) {
    Vue.dayjs = dayjs
        Object.defineProperty(Vue.prototype, 'dayjs', {
            get() {
                return dayjs
            }
        })
    }
}