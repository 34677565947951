const methods = {
    // 입력칸 콤마
    inputToComma(value) {
        if(value){
            return value = this.$toComma(this.$toUnComma(value))
        }else{
            return 0
        }
    },

    // 콤마 추가
    toComma(value) {
        if(value){
            return value.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        }else{
            return 0
        }
    },

    // 콤마 제거
    toUnComma(value) {
        if(value){
            return value.toString().replace(/[^\d]+/g, '')
        }else{
            return 0
        }
    }
}

export default methods