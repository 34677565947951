<template>
    <v-app v-if="snackbar">
        <v-snackbar
            v-model="snackbar"
            class="rounded-15 mt-2"
            rounded="15"
            max-width="288"
            content-class="rounded-15 py-4 px-8"
            timeout="3000"
            light
            top
            elevation="1"
        >
            <v-icon size="18" style="position:absolute; right:12px; top:8px;" color="#F8468D" @click="snackbar = false">mdi-close</v-icon>
            <p
                class="my-0"
                style="color:#424361; font-size:13px; font-weight:bold"
            >
                {{name}}
            </p>
            <p
                class="my-0"
                style="color:#424361; font-size:10px; line-height:14px;"
            >
                <v-icon v-if="regulationMark" size="12" class="pb-2px">
                    mdi-alpha-{{regulationMark.toLowerCase()}}-box-outline
                </v-icon>
                <span class="ml-2px">{{numbers}}</span>
                <span class="ml-2px">{{rarity}}</span>
                <span class="ml-2px">{{set}}</span>
            </p>
            <p
                class="my-0"
                style="color:#424361; font-size:11px; font-weight:bold"
            >
                카드 {{amount == 0? 1 : amount}}장 추가 완료
            </p>
        </v-snackbar>
    </v-app>
</template>

<script>
export default {
    name: 'GlobalSnackbar',

    data: () => ({
        snackbar: false,
        name: "",
        regulationMark: "",
        numbers: "",
        rarity: "",
        set: "",
        amount: 0
    }),

    methods: {
        show(name, regulationMark, numbers, rarity, set, amount) {
            this.name = name
            this.regulationMark = regulationMark
            this.numbers = numbers
            this.rarity = rarity
            this.set = set
            this.amount = amount
            this.snackbar = true
        },

        close() {
            this.snackbar = false
        }
    }
}
</script>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px red !important;
}
</style>