import alert from '@/methods/alert.js'
import commas from '@/methods/commas.js'
import dbAlarm from '@/methods/dbAlarm.js'
import lastVisit from '@/methods/lastVisit.js'
import links from '@/methods/links.js'
import official_league from '@/methods/views/official_league.js'

export default {
    install (Vue) {
        // 얼럿
        Vue.prototype.$buttonAlert = alert.buttonAlert

        // 콤마
        Vue.prototype.$inputToComma = commas.inputToComma
        Vue.prototype.$toComma = commas.toComma
        Vue.prototype.$toUnComma = commas.toUnComma

        // DB 알림
        Vue.prototype.$dbAlarm = dbAlarm.checkAndSend

        // 마지막 접속일 처리
        Vue.prototype.$lastVisit = lastVisit.lastVisit

        // 링크 처리
        Vue.prototype.$linkify = links.linkify

        // 공식대회
        Vue.prototype.$age_group_color = official_league.age_group_color
        Vue.prototype.$load_country_list = official_league.load_country_list
        Vue.prototype.$country_name = official_league.country_name
    }
}